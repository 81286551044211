import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const TFIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }
  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        borderRadius: 1000,
        pl: "18px",
        pt: "18px",
        backgroundImage:`url(${require("../../images/bg.jpg")})`,
        backgroundSize:"500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="TensorFlow"
        arrow
      >
        <svg
        width={params[1]}
        height={params[1]}
          enableBackground="new 0 0 2331.5 2500"
          viewBox="0 0 2331.5 2500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m1332.3 384.6v384.6l666.2 384.6v-384.6zm-1332.3 384.6v384.6l333.1 192.3v-384.6zm999.2 192.3-333.1 192.3v1153.8l333.1 192.4v-769.2l333.1 192.3v-384.6l-333.1-192.3z"
            fill="#e55b2d"
          />
          <path
            d="m1332.3 384.6-999.2 576.9v384.6l666.2-384.6v384.6l333.1-192.3v-769.2zm999.2 192.3-333.1 192.3v384.6l333.1-192.3zm-666.1 769.3-333.1 192.3v384.6l333.1-192.3zm-333.1 576.9-333.1-192.3v769.2l333.1-192.3z"
            fill="#ed8e24"
          />
          <path
            d="m1332.3 0-1332.3 769.2 333.1 192.3 999.2-576.9 666.2 384.6 333.1-192.3zm0 1153.8-333.1 192.3 333.1 192.3 333.1-192.3z"
            fill="#f8bf3c"
          />
        </svg>
      </Tooltip>
    </Box>
  );
};

export default TFIcon;
