import { Box, Link } from "@mui/material";

export const MailIcon = () => {
  return (
    <Link href="mailto:shubham.b.patel@utdallas.edu" target="_blank">
      <Box
        sx={{
          width: "2em",
          height: "2em",
          borderRadius: 1,
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.3)",
          },
          "@media (max-width: 600px)": {
            width: "1.5em",
            height: "1.5em",
          },
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-mail"
        >
          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
          <polyline points="22,6 12,13 2,6" />
        </svg>
      </Box>
    </Link>
  );
};
