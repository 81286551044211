import { Box, Button, Stack, Typography, styled, Link } from '@mui/material';
import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { GitHubIcon } from '../icons/github';
import { LinkedInIcon } from '../icons/linkedin';
import { MailIcon } from '../icons/mail';
import PythonIcon from '../icons/tech stack/PythonIcon';
import CIcon from '../icons/tech stack/C';
import CPPIcon from '../icons/tech stack/Cpp';
import JavaIcon from '../icons/tech stack/java';
import HTMLIcon from '../icons/tech stack/html';
import CSSIcon from '../icons/tech stack/css';
import JSIcon from '../icons/tech stack/js';
import MongoIcon from '../icons/tech stack/mongo';
import MySQLicon from '../icons/tech stack/SQL';
import ReactIcon from '../icons/frameworks/react';
import NodeIcon from '../icons/frameworks/node';
import TailwindIcon from '../icons/frameworks/tailwind';
import GitIcon from '../icons/frameworks/git';
import TFIcon from '../icons/frameworks/tensorflow';
import PyTorchIcon from '../icons/frameworks/pytorch';
import Projects from './ProjectCard';
import Contact from './Contact';

const Intro = styled(Box)(({ theme }) => ({
  width: '75%', // Default width for xs and sm
  padding: "10px",


  '@media (min-width: 850px) and (max-width: 900px)': {
    width: '65%', // 65% width between 850px and 900px
  },

  [theme.breakpoints.up('md')]: {
    width: '62%', // 57% width on md and larger screens
  },
  [theme.breakpoints.up('lg')]: {
    width: '45%', // 57% width on md and larger screens
  },
  '@media (min-width: 1450px)': {
    width: '40%', // 65% width between 850px and 900px
  },
  opacity: 0.9,
  overflowY: "auto",

  "@media (max-width: 600px)": {
    width: "95%",
    height: "60%",
  },

}));

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: "",
  display: "flex",
  width: "100%",
  flexDirection:"column",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: "120vh", // Default height
  '@media (max-width: 415px)': {
    height: "140vh", // 140vh for devices smaller than 415px
  },
  '@media (min-width: 1453px)': {
    minHeight: "80vh", // 80vh height on md and larger screens
  },
  '@media (min-width: 969px) and (max-width: 1452px)': {
    minHeight: "90vh", // 90vh height on md and larger screens
  },
  '@media (max-width: 915px) and (orientation: landscape) and (min-height: 400px)': {
    height: '140vh', // Adjust height for specific landscape mode and minimum height
  },
}));



const Mainpage = () => {

  const [text] = useTypewriter({
    words: ['Full-Stack Developer.', 'ML Engineer.', 'Software Engineer.'],
    loop: {},
  });

const w = window.innerWidth;

const breakpoints = [w <= 932, w <=700, w <= 450];

const fw = [900, 800, 600];
// const fw = [700, 700, 600];

  return (
    <Box sx={{ width: "100%", bgcolor: "black"}}>
      <Section id="section1" sx={{ height: { md: "120vh", xs: "120vh" }, backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)),url(${require("../images/deep-space-nebula-moewalls-com.gif")})`, backgroundSize: "cover" }}>
        <Intro>
          <Typography variant="h1" sx={{ fontWeight: `${fw[0]}`, color: "white", fontSize: { xs: "4rem",md:"5rem" } }}>
            Hi, I am
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: `${fw[0]}`, color: "#1976d2",fontSize: { xs: "4rem",sm:"5rem" } }}>
            Shubham.
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="left"  spacing={3} height="7vh">
            <Link href="https://drive.google.com/file/d/1_qazscGkHNiboKXImHZVNXyz8njjVSCW/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    transform: "scale(1.2)",
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "14px", // Adjust the font size for smaller screens
                  },
                }}
              >
                My resume
              </Button>
            </Link>
            <GitHubIcon />
            <LinkedInIcon />
            <MailIcon />
          </Stack>
          <Stack direction="row" mt={2}> 
            <Typography variant="h3" sx={{ fontWeight: `${fw[1]}`, color: "white", fontSize: { xs: "1.45rem",sm:"2rem",md:"2.5rem" } }}>
              I'm a
            </Typography>
            <Typography variant="h3" sx={{ ml: 1, fontWeight: `${fw[1]}`, color: "#1e88e5",textShadow: "5px 5px 5px black", fontSize: { xs: "1.45rem",sm:"2rem",md:"2.5rem" } }}>
              {text}
              <Cursor cursorStyle="|" />
            </Typography>
          </Stack>
        
          <Typography variant="h6" sx={{ mt: 2, fontWeight: `${fw[2]}`, color: "white", fontSize: { xs: "1rem", sm: "1.2rem" }, width: "100%" }}>
          I am a graduate student at UT Dallas with a background from IIT Madras, specializing in AI and ML. My focus includes NLP, exploring the intricacies of natural language, cognitive processes, and leveraging AI for problem-solving. Particularly interested in RL and experienced in the MERN stack, I also emphasize efficient algorithms and product development.
          </Typography>
        </Intro>
      </Section>

      <Section id="section2" sx={{ justifyContent: "space-evenly", backgroundImage: `linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 0.7)), url(${require("../images/pexels-jakub-novacek-924824.jpg")})`, backgroundSize: "cover" }}>
        <Stack direction="column" alignItems="center" spacing={2} width="90%">
          <Typography variant="h2" sx={{ fontWeight: `${fw[0]}`, color: "white", backgroundColor: "", width: "100%" }}>
            Skills
          </Typography>
          {
            breakpoints[2] ? (
              <Typography variant="h6" sx={{ fontWeight: `${fw[2]}`, color: "white", backgroundColor: "", width: "100%" }}>
                Technical Languages
              </Typography>
            ) : (
              <Typography variant="h6" sx={{ fontWeight: `${fw[2]}`, color: "white", backgroundColor: "", width: "100%" }}>
                TECHNICAL LANGUAGES
              </Typography>
            )
          }
          
          {breakpoints[0] ? (
              breakpoints[1] ? (
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                  <Stack direction="row" spacing={4}>
                    <PythonIcon />
                    <CPPIcon />
                    <CIcon />  
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <HTMLIcon />
                    <CSSIcon />
                    <JSIcon />
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <MongoIcon />
                    <MySQLicon />
                    <JavaIcon />          
                  </Stack>
                </Stack>
              ) :             
              (<Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                <Stack direction="row" spacing={4}>
                    <PythonIcon />
                    <CPPIcon />
                    <CIcon />
                    <JavaIcon /> 
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <HTMLIcon />
                    <CSSIcon />
                    <JSIcon />
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <MongoIcon />
                    <MySQLicon />          
                  </Stack>
              </Stack>)
              
              ) : (
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                  <Stack direction="row" spacing={3}>
                    <PythonIcon />
                    <CPPIcon />
                    <CIcon />
                    <JavaIcon />
                    <MySQLicon />
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <HTMLIcon />
                    <CSSIcon />
                    <JSIcon />
                    <MongoIcon />
                  </Stack>
                </Stack>
              )
            }
          {
            breakpoints[2] ? (
              <Typography variant="h6" sx={{ fontWeight: `${fw[2]}`, color: "white", backgroundColor: "", width: "100%" }}>
                Technologies and Frameworks
              </Typography>
            ) : (
              <Typography variant="h6" sx={{ fontWeight: `${fw[2]}`, color: "white", backgroundColor: "", width: "100%" }}>
                TECHNOLOGIES AND FRAMEWORKS
              </Typography>
            )
          }
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Stack direction="row" spacing={3}>
              <TFIcon />
              <PyTorchIcon />
              <GitIcon />
            </Stack>
            <Stack direction="row" spacing={3}>
              <ReactIcon />
              <NodeIcon />
              <TailwindIcon />
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <Section id="section3" sx={{backgroundImage:`radial-gradient(ellipse at center, rgba(0, 0, 0, 0.55) 30%, rgba(0, 0, 0, .95) 70%, rgba(0, 0, 0, 1) 100%), url(${require("../images/316617.jpg")})`, backgroundSize: "cover", '@media (max-width: 700px)': {

    }}}>
        <Stack direction="column"alignItems="center" justifyContent="center" width="90%" spacing={5}  >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontWeight: `${fw[0]}`,
              color: "white",
              width: "100%",
            }}
          >
            Projects
          </Typography>
          <Projects />
        </Stack>
      </Section>

      <Section
        id="section4"
        sx={{
          position: 'relative', // Add position: relative;
          height: '60vh', // Default height
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, 0.2)), url(${require("../images/placeholder.jpeg")})`,
          backgroundSize: 'cover',
          '@media (max-width: 969px)': {
            height: '80vh', // Height when screen size is less than 969px
          },
        }}
      >
        <Contact />
        <Typography
          variant="h7"
          sx={{
            position: 'absolute',
            bottom: 0,
            color: 'white',
            bgcolor: 'black',
            fontWeight: 300,
            textAlign: 'left',
            p:"2px"
          }}
        >
          Copyright © 2023 Shubham Patel
        </Typography>
      </Section>
    </Box>
  );
}

export default Mainpage;
