import React, { useState, useEffect, useRef } from 'react';
import './contact.scss';
import { Typography } from '@mui/material';
import emailjs from '@emailjs/browser'

const Contact = () => {
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   message: '',
  // });

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');

  // const updateWindowWidth = () => {
  //   setWindowWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', updateWindowWidth);

  //   return () => {
  //     window.removeEventListener('resize', updateWindowWidth);
  //   };
  // }, []);

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch('http://localhost:3000/send-email', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     const responseData = await response.json();

  //     if (response.ok) {
  //       console.log('Email sent successfully');
  //       // Display success message
  //       setSuccessMessage('Email sent successfully');
  //       // Clear the form
  //       setFormData({ name: '', email: '', message: '' });
  //     } else {
  //       console.error('Failed to send email');
  //       // Display error message
  //       setErrorMessage(`Failed to send email: ${responseData.error}`);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Display error message
  //   }
  // };

  const formRef = useRef()

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p2ysu9f', 'template_b3jcw7h', formRef.current, 'XQR4dLIyjBmCI1roe')
      .then((result) => {
          setSuccess(true)
          // console.log(result.text);
      }, (error) => {
          // console.log(error.text);
          setError(false)
      });
  };

  const shift = window.innerWidth <= 650;

  return (
    <div className="contact">
      <div className="textContainer">
        {shift ? (
          <Typography variant="h4" sx={{ color: 'white', fontWeight: 900, mb: 2 }}>
            Let's connect
          </Typography>
        ) : (
          <Typography variant="h2" sx={{ color: 'white', fontWeight: 900, mb: 2 }}>
            Let's connect
          </Typography>
        )}

        <div className="item">
          <h2>Address</h2>
          <span>Dallas, Texas</span>
        </div>
        <div className="item">
          <h2>Phone</h2>
          <span>+1 (945) 274-5011</span>
        </div>
      </div>
      <div className="formContainer">
        <form onSubmit={sendEmail} ref={formRef}>
          <input
            type="text"
            name="from_name"
            // value={formData.name}
            // onChange={handleChange}
            required
            placeholder="Name"
          />
          <input
            type="email"
            name="email"
            // value={formData.email}
            // onChange={handleChange}
            required
            placeholder="Email"
          />
          <textarea
            rows={8}
            name="message"
            // value={formData.message}
            // onChange={handleChange}
            placeholder="Message"
          />
          <button type="submit" sx={{ cursor: 'pointer' }}>
            Submit
          </button>

        {success && <p style={{ color: 'green' }}>Success</p>}
        {error && <p style={{ color: 'red' }}>Error</p>}
        </form>

      </div>
    </div>
  );
};

export default Contact;
