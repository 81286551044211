import { Box, Link } from "@mui/material";

export const LinkedInIcon = () => {
  return (
    <Link href="https://www.linkedin.com/in/shubham-patel-80203a219/" target="_blank">
      <Box
        sx={{
          width: "2em",
          height: "2em",
          backgroundColor: "#1e88e5",
          borderRadius: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.3)",
          },
          "@media (max-width: 600px)": {
            width: "1.5em",
            height: "1.5em",
          },
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-linkedin"
        >
          <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
          <rect x="2" y="9" width="4" height="12" />
          <circle cx="4" cy="4" r="2" />
        </svg>
      </Box>
    </Link>
  );
};
