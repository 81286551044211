import React, { useState, useEffect } from "react";import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const JSIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }
  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        borderRadius: 1000,
        pl: "18px",
        pt: "18px",
        backgroundImage:`url(${require("../../images/bg.jpg")})`,
        backgroundSize:"500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="JavaScript"
        arrow
      >
 <svg
        xmlns="http://www.w3.org/2000/svg"
        width={params[1]}
        height={params[1]}
        viewBox="-90 -70 1052 1052"
      >
        <path fill="#f0db4f" d="M0 0h1052v1052H0z" />
        <path
          d="M965.9 801.1c-7.7-48-39-88.3-131.7-125.9-32.2-14.8-68.1-25.399-78.8-49.8-3.8-14.2-4.3-22.2-1.9-30.8 6.9-27.9 40.2-36.6 66.6-28.6 17 5.7 33.1 18.801 42.8 39.7 45.4-29.399 45.3-29.2 77-49.399-11.6-18-17.8-26.301-25.4-34-27.3-30.5-64.5-46.2-124-45-10.3 1.3-20.699 2.699-31 4-29.699 7.5-58 23.1-74.6 44-49.8 56.5-35.6 155.399 25 196.1 59.7 44.8 147.4 55 158.6 96.9 10.9 51.3-37.699 67.899-86 62-35.6-7.4-55.399-25.5-76.8-58.4-39.399 22.8-39.399 22.8-79.899 46.1 9.6 21 19.699 30.5 35.8 48.7 76.2 77.3 266.899 73.5 301.1-43.5 1.399-4.001 10.6-30.801 3.199-72.101zm-394-317.6h-98.4c0 85-.399 169.4-.399 254.4 0 54.1 2.8 103.7-6 118.9-14.4 29.899-51.7 26.2-68.7 20.399-17.3-8.5-26.1-20.6-36.3-37.699-2.8-4.9-4.9-8.7-5.601-9-26.699 16.3-53.3 32.699-80 49 13.301 27.3 32.9 51 58 66.399 37.5 22.5 87.9 29.4 140.601 17.3 34.3-10 63.899-30.699 79.399-62.199 22.4-41.3 17.6-91.3 17.4-146.6.5-90.2 0-180.4 0-270.9z"
          fill="#323330"
        />
      </svg>
      </Tooltip>
    </Box>
  );
};

export default JSIcon;



