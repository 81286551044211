import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const projectDetail = [
  {
    name: "A YouTube Clone",
    description:
      "A fully responsive YouTube App with video categories, channel pages, video detail page and player as well as related videos made using RapidAPI and Material UI.",
  },
  {
    name: "Improved Vector Space Model",
    description:
      "Developed and improved a functioning search engine based on the Vector Space Model using better representation of weights, Latent Semantic Analysis and BM-25.",
  },
  {
    name: "ML & RL Algorithms",
    description:
      "Implemented SARSA and Q-Learning on a custom grid world with high rewards in less than 1000 episodes, Implemented Deep Q-Networks, Actor-Critic, SMDPs and Intra-Option algorithms in OpenAI gym environment.",
  },
];

const colorDetail = [
  { from: "red", to: "#0C0C0C" },
  { from: "#039BEF", to: "#0C0C0C" },
  { from: "#3333FF", to: "#0C0C0C" },
];

// const colorDetail = [
//   { from: "red", to: "#0C0C0C" },
//   { from: "pink", to: "#0C0C0C" },
//   { from: "#008000", to: "#fff" },
// ];

var params = ["230px", "390px"];
var sz = ["cover"]
if (window.innerWidth <= 700) {
  params = ["225px", "300px"]
  sz = "300px 230px"

}

const Projects = () => {
  return (
    <Grid container justifyContent="space-evenly" columnSpacing={5} rowSpacing={5} width="100%">
      {projectDetail.map((project, index) => (
        <Grid item key={index}>
          <Box
            sx={{
              position: "relative",
              top:"-20px",
              left:"-20px",
              border: "#DDD solid 3px",
              backgroundImage: `url(${require(`../images/project${
                index + 1
              }.jpg`)})`,
              backgroundSize: `${sz}`,
              height: `${params[0]}`,
              width: `${params[1]}`,
              borderRadius: "15px",
              transition: "transform 0.3s ease",
              display: "flex", // Added display: flex
              // flexDirection: "column", // Added flexDirection: column
              // justifyContent: "center", // Added justifyContent: center
              // alignItems: "center", // Added alignItems: center
              "&:hover": {
                transform: "scale(1.15)",
                "& .innerBox": {
                  height: "100%",
                  transition: "height 0.3s ease-out",
                },
              },
              "&:not(:hover)": {
                transform: "scale(1)",
                "& .innerBox": {
                  height: "0px",
                  transition: "height 0.3s ease-out",
                },
              },
            }}
          >
            <Box
              className="innerBox"
              justifyContent="space-evenly"
              position="absolute"
              bottom="0"
              left="0"
              // right="0"
              alignItems="center"
              sx={{
                height: "0px",
                opacity: 0.9,
                background: `linear-gradient(${colorDetail[index].from}, ${colorDetail[index].to});`,
                borderRadius: "13px",
                // width:"110%",
                overflow: "hidden",
                // width: "100%", // Added width: 100%
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
                sx={{
                  mt: 2,
                  fontWeight: 900,
                  color: "white",
                  backgroundColor: "",
                  width: "100%",
                }}
              >
                {project.name}
              </Typography>
              <Typography
                textAlign="center"
                sx={{
                  mt: 2,
                  ml: 2,
                  fontWeight: 500,
                  fontSize: 14,
                  color: "white",
                  backgroundColor: "",
                  width: "90%",
                }}
              >
                {project.description}
              </Typography>
              <Link
                href={`https://github.com/shubhampatel77`}
                target="_blank"
                sx={{mt: window.innerWidth > 630 ? 3 : 0 ,display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <OpenInNewIcon sx={{ color: "white" }} />
              </Link>

            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Projects;
