import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const PythonIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }

  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        pl: "18px",
        pt: "18px",
        borderRadius: "50%", // Make the box perfectly round
        backgroundImage: `url(${require("../../images/bg.jpg")})`,
        backgroundSize: "500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
        // "@media (max-width: 550px)": {
        //   width: "60%", // Smaller width for screens < 550px
        //   height: "60%", // Smaller height for screens < 550px
        // },
        // "@media (max-width: 450px)": {
        //   width: "40%", // Further reduce width for screens < 450px
        //   height: "40%", // Further reduce height for screens < 450px
        // },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="Python"
        arrow
      >
        <svg
          width={params[1]}
          height={params[1]}
          viewBox="0 0 256 255" // Adjusted viewBox
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin meet"
        >
          <defs>
            <linearGradient
              x1="12.959%"
              y1="12.039%"
              x2="79.639%"
              y2="78.201%"
              id="a"
            >
              <stop stop-color="#387EB8" offset="0%" />
              <stop stop-color="#366994" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="19.128%"
              y1="20.579%"
              x2="90.742%"
              y2="88.429%"
              id="b"
            >
              <stop stop-color="#FFE052" offset="0%" />
              <stop stop-color="#FFC331" offset="100%" />
            </linearGradient>
          </defs>
          <path
            d="M126.916.072c-64.832 0-60.784 28.115-60.784 28.115l.072 29.128h61.868v8.745H41.631S.145 61.355.145 126.77c0 65.417 36.21 63.097 36.21 63.097h21.61v-30.356s-1.165-36.21 35.632-36.21h61.362s34.475.557 34.475-33.319V33.97S194.67.072 126.916.072zM92.802 19.66a11.12 11.12 0 0 1 11.13 11.13 11.12 11.12 0 0 1-11.13 11.13 11.12 11.12 0 0 1-11.13-11.13 11.12 11.12 0 0 1 11.13-11.13z"
            fill="url(#a)"
          />
          <path
            d="M128.757 254.126c64.832 0 60.784-28.115 60.784-28.115l-.072-29.127H127.6v-8.745h86.441s41.486 4.705 41.486-60.712c0-65.416-36.21-63.096-36.21-63.096h-21.61v30.355s1.165 36.21-35.632 36.21h-61.362s-34.475-.557-34.475 33.32v56.013s-5.235 33.897 62.518 33.897zm34.114-19.586a11.12 11.12 0 0 1-11.13-11.13 11.12 11.12 0 0 1 11.13-11.131 11.12 11.12 0 0 1 11.13 11.13 11.12 11.12 0 0 1-11.13 11.13z"
            fill="url(#b)"
          />
        </svg>
      </Tooltip>
    </Box>
  );
};

export default PythonIcon;
