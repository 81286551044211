import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const PyTorchIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }
  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        borderRadius: 1000,
        pl: "18px",
        pt: "18px",
        // backgroundImage:"url(https://images.unsplash.com/photo-1617957689233-207e3cd3c610?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
        // backgroundSize:"300px",
        backgroundImage:`url(${require("../../images/bg.jpg")})`,
        backgroundSize:"500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="PyTorch"
        arrow
      >
       <svg
        viewBox="-1.54320845 20.305 35 5"
        width={params[1]}
        height={params[1]}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m24.27 17.059-1.915 1.918c3.192 3.191 3.192 8.394 0 11.585-3.195 3.196-8.394 3.196-11.59 0-3.19-3.19-3.19-8.394 0-11.585l5.11-5.11.64-.73v-3.832l-7.757 7.754a10.854 10.854 0 0 0 0 15.421 10.854 10.854 0 0 0 15.422 0c4.379-4.289 4.379-11.222.09-15.421zm0 0"
          fill="#ee4c2c"
        />
        <path
          d="m21.898 15.05a1.461 1.461 0 0 1 -2.921 0 1.46 1.46 0 0 1 2.921 0zm0 0"
          fill="#ee4c2c"
        />

      </svg>
      </Tooltip>
    </Box>
  );
};

export default PyTorchIcon;