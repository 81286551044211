import React, { useState, useEffect } from "react";import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const HTMLIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }

  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        borderRadius: 1000,
        pl: "18px",
        pt: "18px",
        backgroundImage:`url(${require("../../images/bg.jpg")})`,
        backgroundSize:"500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="HTML"
        arrow
      >
  <svg
        fill="none"
        width={params[1]}
        height={params[1]}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 141.53199999999998"
      >
        <path
          d="M10.383 126.894L0 0l124 .255-10.979 126.639-50.553 14.638z"
          fill="#e34f26"
        />
        <path
          d="M62.468 129.277V12.085l51.064.17-9.106 104.851z"
          fill="#ef652a"
        />
        <path
          d="M99.49 41.362l1.446-15.49H22.383l4.34 47.49h54.213L78.81 93.617l-17.362 4.68-17.617-5.106-.936-12.085H27.319l2.128 24.681 32 8.936 32.255-8.936 4.34-48.17H41.107L39.49 41.362z"
          fill="#fff"
        />
      </svg>
      </Tooltip>
    </Box>
  );
};

export default HTMLIcon;


