import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "./style.css";

const CIcon = () => {
  const [bounce, setBounce] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bounceIcon = () => {
      setBounce(true);
      const timeout = setTimeout(() => setBounce(false), 1000);
      return () => clearTimeout(timeout);
    };

    const interval = setInterval(bounceIcon, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  var params = ["7em", "6em"];
  if (window.innerWidth <= 500 && window.innerWidth > 450) {
    params = ["5.5em", "4.5em"]
  } else if (window.innerWidth <= 450) {
    params = ["4em", "3em"]
  }
  return (
    <Box
      ref={ref}
      sx={{
        width: `${params[0]}`,
        height: `${params[0]}`,
        backgroundColor: "white",
        borderRadius: "50%", // Make the box perfectly round
        pl: "18px",
        pt: "18px",
        backgroundImage: `url(${require("../../images/bg.jpg")})`,
        backgroundSize: "500px 300px",
        transition: "transform 1.2s ease-in-out",
        transform: `translateY(${bounce ? "-.7em" : "0"})`, // Vertical bounce motion
        "&:hover": {
          animation: inView ? "hoverAnimation 1.2s infinite" : "none", // Trigger hover animation only when in view
          transform: `translateY(${bounce ? "-1em" : "0"})`, // Vertical bounce motion on hover
        },
        // "@media (max-width: 550px)": {
        //   width: "60%", // Smaller width for screens < 550px
        //   height: "60%", // Smaller height for screens < 550px
        // },
        // "@media (max-width: 450px)": {
        //   width: "40%", // Further reduce width for screens < 450px
        //   height: "40%", // Further reduce height for screens < 450px
        // },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        placement="top"
        disableHoverListener={!isHovered}
        title="C Programming"
        arrow
      >
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={params[1]}
        height={params[1]}
        viewBox="32.18585611720149 20.47 223.6851360941233 247.05999999999997"
        fill="#004482"
        stroke=""
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="c-logo"
        preserveAspectRatio="xMinYMin meet"
      >
          <path
          d="M252.71 93.61a21.67 21.67 0 0 0-2.65-10.87 20.74 20.74 0 0 0-7.87-7.67Q198.77 50 155.32 25c-7.8-4.51-15.36-4.35-23.11.23C120.69 32 63 65.09 45.81 75.06c-7.08 4.1-10.52 10.38-10.52 18.54v100.8a21.77 21.77 0 0 0 2.55 10.66 20.63 20.63 0 0 0 8 7.88c17.19 10 74.89 43.05 86.41 49.85 7.75 4.58 15.31 4.74 23.12.23q43.41-25.08 86.87-50.09a20.63 20.63 0 0 0 8-7.88 21.77 21.77 0 0 0 2.55-10.66V93.61z"
          fill="#004482"
        />
        <path
          d="M252.73 194.4a21.72 21.72 0 0 1-2.55 10.66 18.58 18.58 0 0 1-1.45 2.24L144 144l98.19-68.93a20.72 20.72 0 0 1 7.86 7.67 21.57 21.57 0 0 1 2.66 10.87c.02 33.6.02 100.79.02 100.79z"
          fill="#00599c"
        />
        <path
          d="M250.05 82.74L37.81 205.06a21.75 21.75 0 0 1-2.53-10.65V93.6c0-8.16 3.45-14.44 10.52-18.54C63 65.09 120.69 32 132.22 25.21c7.73-4.58 15.3-4.74 23.1-.23q43.41 25.08 86.87 50.09a20.72 20.72 0 0 1 7.86 7.67z"
          fill="#659ad2"
        />
        <path
          d="M148.2 184.72a39.91 39.91 0 0 1-35-20.63q-.53-.94-1-1.92A39.94 39.94 0 0 1 179 119.4c.53.64 1 1.31 1.53 2 .24.33.48.66.7 1l35.07-20.2q-1.28-2.06-2.68-4c-.49-.69-1-1.35-1.48-2A79.9 79.9 0 0 0 78 181.92c.34.64.69 1.27 1 1.9a79.91 79.91 0 0 0 136.86 3.62l-34.29-20.73a39.88 39.88 0 0 1-33.37 18.01z"
          fill="#fff"
        />
      </svg>
      </Tooltip>
    </Box>
  );
};

export default CIcon;

