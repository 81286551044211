import { Box, ThemeProvider } from "@mui/material";
import Mainpage from "./components/Mainpage";
import Navbar from "./components/Navbar";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar />
        <Mainpage />
      </Box>
    </ThemeProvider>
  );
}

export default App;
