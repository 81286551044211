import { Stack, AppBar, Toolbar, Typography, styled, Link, Popover, List, ListItem, ListItemText, Box } from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import React, { useState } from 'react'

const StyledToolbar = styled(Toolbar)({
  backgroundColor: "black",
  display: "flex",
  justifyContent: "space-between",
  padding: "5px 5px",
  borderBottom: "5px solid #999999",
})

const Navbar = () => {
  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    handleCloseMenu();
  };

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const isMobile = window.innerWidth <= 950;

  return (
    <AppBar position="fixed" sx={{ opacity:1}}>
      <StyledToolbar>
        <Typography variant="h4" sx={{fontWeight: 900, fontSize: { xs: "1.5rem",sm:"2.5rem",md:"2.5rem" }}}>
          Shubham Patel
        </Typography>
        
        {isMobile ? (
          <Box>
            <MenuRoundedIcon onClick={handleOpenMenu} sx={{ cursor: "pointer" }} />

            <Popover
              open={Boolean(menuAnchor)}
              anchorEl={menuAnchor}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }} 
            >
              <List sx ={{backgroundColor:"#080808", color:"white", border:"solid 1px  #999999"}}>
                <ListItem onClick={() => handleScroll("section1")}>
                  <ListItemText primary="Home" sx={{ cursor: "pointer" }}/>
                </ListItem>
                <ListItem onClick={() => handleScroll("section2")}>
                  <ListItemText primary="Skills" sx={{ cursor: "pointer" }}/>
                </ListItem>
                <ListItem onClick={() => handleScroll("section3")}>
                  <ListItemText primary="Projects" sx={{ cursor: "pointer" }}/>
                </ListItem>
                <ListItem onClick={() => handleScroll("section4")}>
                  <ListItemText primary="Let's Connect" sx={{ cursor: "pointer" }}/>
                </ListItem>
              </List>
            </Popover>
          </Box>
        ) : (<Stack direction="row" spacing={7}>
              <Link onClick={() => handleScroll("section1")} sx={{ cursor: "pointer" }}>
                <Typography  variant="h6" sx={{ color:"white"}}>
                  Home
                </Typography>
              </Link>
              
              <Link onClick={() => handleScroll("section2")} sx={{ cursor: "pointer" }}>
                <Typography variant="h6" sx={{ color:"white"}}>
                  Skills
                </Typography>
              </Link>

              <Link onClick={() => handleScroll("section3")} sx={{ cursor: "pointer" }}>
                <Typography variant="h6" sx={{ color:"white"}}>
                  Projects
                </Typography>
              </Link>
              <Link onClick={() => handleScroll("section4")} sx={{ cursor: "pointer" }}>
                <Typography variant="h6" sx={{color:"white"}}>
                  Let's Connect
                </Typography>
              </Link>
            </Stack>)
        }
      </StyledToolbar>
    </AppBar>
  )
}

export default Navbar;
