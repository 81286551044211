import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette:{
    primary:{
      main: "#111",
      ligh: "skyblue"
    },
    secondary:{
      main:"#15c630"
    },
    otherColor:{
      main:"#999"
    }
  },
  typography: {
    fontFamily: "Tahoma, Verdana, Helvetica, sans-serif",
  },
})